import Swiper from '@atoms/Swiper';
import { TypoMBold, TypoXSBold } from '@atoms/Typos';
import Link from '@components/Link';
import { useAppSelector } from '@hooks/useAppSelector';
import TravelStoryCard from '@molecules/TravelStoryCard';
import { BREAKPOINT } from '@utils/constants';
import routes, { route } from '@utils/routes';
import useTranslation from 'next-translate/useTranslation';
import { SwiperSlide } from 'swiper/react';
import styles from './css';

const TravelStorySlider = ({ type }: { type: 'latest' | 'popular' }) => {
  const { t } = useTranslation('travel-story');
  const {
    travelStories: { travelStoriesLists },
  } = useAppSelector((state) => ({
    travelStories: state.travelStories,
  }));

  const options = {
    breakpoints: {
      [BREAKPOINT.desktopL]: {
        slidesPerView: 4,
      },
      [BREAKPOINT.mobileS]: {
        slidesPerView: 2.1,
      },
      [BREAKPOINT.mobile]: {
        slidesPerView: 3,
      },
    },
    centeredSlides: false,
    loop: false,
    observer: true,
    slidesPerView: 1.5,
    spaceBetween: 16,
  } as const;

  if (travelStoriesLists[type].travel_stories.length === 0) {
    return null;
  }

  return (
    <div key={`travel-story-slider-${type}`} className="travel-story-slider">
      <div className="travel-story-slider-title-cont">
        <TypoMBold text={t(`slider.title.${type}`)} />
        <Link href={`${routes.travelStories}?tab=${type}`}>
          <TypoXSBold color="primary" text={t('seeMore')} />
        </Link>
      </div>
      <div className="travel-story-slider-inner">
        <Swiper name="travel-story" options={options}>
          {travelStoriesLists[type].travel_stories
            .slice(0, 20) //   limit travel stories amount to render
            .map((travelStory) => (
              <SwiperSlide
                key={travelStory?.title}
                className="swiper-slide travel-story-slider-item"
              >
                <TravelStoryCard travelStory={travelStory} />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default TravelStorySlider;
